import AnimationObject from "./AnimationObject";

/**
 * 成都沃沃飞长空
 * 2021.7.1-wq(慕景)
 */

/**
 * Animation manager
 * This class is used to manager all animation objects
 *
 * There should only have one instance of AnimationManager in Viewer(or Map3D);
 */
export default class AnimationManager {
  constructor() {
    this._fps = 30;
    this._now;
    this._then = Date.now();
    this._interval = 1000 / this._fps;
    this._delta;
    this._stopHandler = null;
    this._animationObjects = [];
    this._loop = false;
  }

  start() {
    this._loop = true;
    this.animationLoop();
  }

  add(aObject) {
    if (!(aObject instanceof AnimationObject)) {
      throw console.error("Object must is an instance of AnimationObject");
    }
    this._animationObjects.push(aObject);
    if (!this._loop) {
      this.start();
    }
  }

  remove(id) {
    let targetIndex = this._animationObjects.findIndex(x => x.id === id);
    if (targetIndex-1) {
      this._animationObjects[targetIndex].remove();
      this._animationObjects.splice(targetIndex, 1);
    }
  }

  find(id){
    let targetIndex = this._animationObjects.findIndex(x => x.id === id);
    if (targetIndex>-1) {
      return this._animationObjects[targetIndex];
    }
  }

  trigger() {
    this._loop = !this._loop;
  }

  animationLoop() {
    this._stopHandler = requestAnimationFrame(() => {
      this.animationLoop();
    });
    if (!this._loop) {
      return;
    }
    this._now = Date.now();
    this._delta = this._now - this._then;
    let trick = false;
    if (this._delta > this._interval) {
      this._then = this._now - (this._delta % this._interval);
      trick = true;
    }
    for (let i = 0; i < this._animationObjects.length; i++) {
      if (!this._animationObjects[i].pasue) {
        if (this._animationObjects[i].driverSelf) {
          this._animationObjects[i].frameTick(this._now);
        } else if (trick && !this._animationObjects[i].driverSelf) {
          //do something
          this._animationObjects[i].animation();
        }
      }
    }
  }

  stop() {
    this._loop = false;
    cancelAnimationFrame(this._stopHandler);
  }
}
