/*
 * @Description:
 * @Autor: wq
 * @Date: 2022-05-25 16:09:48
 * @watermark: 成都傲势科技
 * @LastEditors: wq
 */
class LayerManager {
  constructor(props) {
    props = Cesium.defaultValue(props, {});
    this._map = props.map;
    this._layers = props.layers ? props.layers : [];
  }
  /**
   * 添加图层
   * @param {*} layer
   */
  add(layer) {
    let oldLayer = this.find(layer.name);
    if (!oldLayer) {
      this._layers.push(layer);
      layer.registeManager(this)
      this._map.dataSources.add(layer);
      if (layer.primitives) {
        this._map.scene.primitives.add(layer.primitives);
      }
    }
  }
  /**
   * 查找图层
   * @param {*} layerId
   * @returns
   */
  find(layerId) {
    return this._layers.find((item) => {
      return item.name === layerId;
    });
  }
  /**
   * 移除图层
   * @param {*} layer
   */
  remove(layer) {
    this._map.dataSources.remove(layer, false)
    layer.logOff()
    if (layer.primitives) {
        this._map.scene.primitives.remove(layer.primitives);
      }
    this.removeById(layer.name);
  }
  /**
   * 通过ID移除图层
   * @param {*} layerId
   */
  removeById(layerId) {
    this._layers.splice(
      this._layers.findIndex((item) => item.name === layerId),
      1
    );
  }

  get layers() {
    return this._layers;
  }
  set layers(layers) {
    this._layers = layers;
  }
}
export default LayerManager;
