import NormalOperation from "./interactive/operation/NormalOperation";
import BaseOperation from "./interactive/operation/BaseOperation";
class Interactive {
  constructor(options) {
    options = Object.assign({}, options);
    this._map = options.map;
    this._registerHandler = new Cesium.ScreenSpaceEventHandler(
      this._map.scene.canvas
    );
    this._layer = options.layer;
    this._type = options.type ? options.type : null;
    this._current = {};
    this.init();
  }

  get current() {
    return this._current;
  }
  // 不太明确属性,有空深入
  get type() {
    return this._type;
  }

  init() {
    //去除默认的选择事件
    this._registerHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    this._registerHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_CLICK
    );
    this._map.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );
    this._map.cesiumWidget.screenSpaceEventHandler.removeInputAction(
      Cesium.ScreenSpaceEventType.LEFT_CLICK
    );
    this.setInteractive(new NormalOperation());
  }

  setInteractive(operation) {
    this.removeInteractive(this._current);
    if(!operation){
      operation = new NormalOperation();
    }
    this._current = operation;
    this._type = operation.name;
    this._registerOperation();

    this._current.layer = operation.layer;
    this._layer = operation.layer;
  }

  removeInteractive(operation) {
    this.clearOperation()
    if (this._current.name) {
      operation.eventMap.forEach((handler) => {
        this._registerHandler.removeInputAction(
          Cesium.ScreenSpaceEventType[handler.type]
        );
      });
    }
  }

  removeHandler(handlerType) {
    if (this._current.name) {
      this._current.eventMap.forEach((handler) => {
        if (handler.type === handlerType) {
          this._registerHandler.removeInputAction(
            Cesium.ScreenSpaceEventType[handler.type]
          );
        }
      });
    }
  }

  clearOperation() {
    if (this._current && this._current.destroy) {
      this._current.destroy();
    }
  }

  _registerOperation() {
    let self = this;
    this._current.eventMap.forEach((handler) => {
      this._registerHandler.setInputAction(function (event) {
          //设置监听方法
          handler.callback(self._map, event, self._layer);
        }, Cesium.ScreenSpaceEventType[handler.type], handler.modefiy
      );
    });
  }

  triggerEditModel(operation, params) {
    if (operation instanceof BaseOperation) {
      this.setInteractive(operation);
    } else if (typeof operation == 'boolean') {
      this.setInteractive(new NormalOperation(params));
    }
  }
}
export default Interactive;
