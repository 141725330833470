/**
 * @param {} options
 * @param {} options.driverSelf decide animation trigger way
 *                              false: use the common interval in AnimationManager
 *                              true: trigger animation by self, you need be sure that your code in frameTick function and call the animation function in the end
 * 
 */
 export default class AnimationObject{
    constructor(options){
        if(!options){
            options = {};
        }
        this._driverSelf = (options.driverSelf===null||options.driverSelf===undefined)?false:options.driverSelf;
        this._pasue = (options.pasue===null||options.pasue===undefined)?false:options.pasue;
        this._id = (options.id===null||options.id===undefined||options.id === "")?Cesium.createGuid(): options.id;
        this._entity = null;
        this._driver = null;
    }

    get driverSelf(){
        return this._driverSelf;
    }

    get entity(){
        return this._entity;
    }

    get driver(){
        return this._driver;
    }

    get pasue(){
        return this._pasue;
    }
    
    get id(){
        return this._id
    }

    frameTick(frameTime){
        this.animation();
    }

    trigger(){
        this._pasue = !this._pasue;
    }

    animation(){

    }

    remove(){

    }
}