import Interactive from "./Interactive";
import LayerManager from "./LayerManager";
import MiddleIcon from "@/assets/map/MiddleIcon.png";
import AnimationManager from "./animation/AnimationManager";
import { distances, tdtUrl, getTerrainProvider } from "./mapConfig";

class Map3D extends Cesium.Viewer {
  static maximumZoomDistance = 1000 * 1000 * 5
  static minimumZoomDistance = 200
  static homeLongitude = 103.59328136622959
  static homeLatitude = 35.06422583115814
  constructor(id, props) {
    const layers = [];
    // 使用的瓦片地址（中英文）
    const envMapURL = process.env.VUE_APP_MAP_LOCAL
    const hasLocal = !!envMapURL
    const hasLocalTerrain = !!process.env.VUE_APP_TERRAIN_LOCAL
    const imageryProvider = hasLocal? 
      new Cesium.UrlTemplateImageryProvider({
        url: envMapURL,
        maximumLevel: 17,
      }): 
      new Cesium.UrlTemplateImageryProvider({
        url: `${tdtUrl}/DataServer?T=img_w&x={x}&y={y}&l={z}&tk=7f3a6463a4e8b66fbfb22a3b78a9c710`,
        subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
        tilingScheme: new Cesium.WebMercatorTilingScheme(),
        maximumLevel: 17,
      });
    let terrain = null;
    if (props.is3D) {
      terrain = hasLocalTerrain? 
        new Cesium.CesiumTerrainProvider({
          url: process.env.VUE_APP_TERRAIN_LOCAL
        }): 
        // null;
        new Cesium.CesiumTerrainProvider({
          url: 'https://data.mars3d.cn/terrain'
          // url: 'https://www.freexgis.com/web-data/terrain'
          // url: 'https://www.supermapol.com/realspace/services/3D-stk_terrain/rest/realspace/datas/info/data/path'
        });
    }
    imageryProvider.name = "baseImg";
    layers.push(imageryProvider);
    let defaultProp = {
      animation: false, //是否显示动画控件
      shouldAnimate: true,
      homeButton: false, //是否显示Home按钮
      fullscreenButton: false, //是否显示全屏按钮
      baseLayerPicker: false, //是否显示图层选择控件
      geocoder: false, //是否显示地名查找控件
      timeline: false, //是否显示时间线控件
      sceneModePicker: false, //是否显示投影方式控件
      selectionIndicator: false,
      navigationHelpButton: false, //是否显示帮助信息控件
      infoBox: false, //是否显示点击要素之后显示的信息
      requestRenderMode: false, //启用请求渲染模式
      scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
      sceneMode: 2, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
      fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
      imageryProvider: imageryProvider,
      terrainProvider: terrain,
      orderIndependentTranslucency: false,
      contextOptions: {
        webgl: {
          alpha: true,
        },
      },
    };
    let params = Object.assign(defaultProp, props);
    super(id, params);
    // 交互管理
    this._interactive = params.interactive? params.interactive : new Interactive({ map: this });
    // 图层管理
    this._layerManager = params.layerManager? params.layerManager : new LayerManager({ map: this });
    // 动画管理
    this._animationManager = params.animationManager? params.animationManager: new AnimationManager();
    this.init(defaultProp);
    if(!hasLocal){
      // 添加天地图道路等信息
      this.loadTDMap(defaultProp.imageryProvider);
    }
  }

  get layerManager() {
    return this._layerManager;
  }

  get baseLayer() {
    return this.imageryLayers._layers;
  }

  get interactive() {
    return this._interactive;
  }

  get animationManager() {
    return this._animationManager;
  }

  init(defaultProp) {
    let lon = Map3D.homeLongitude
    let lat = Map3D.homeLatitude
    let height = Map3D.maximumZoomDistance
    if (!!window.global.VUE_APP_HOME_LOCATION) {
      let arr =  window.global.VUE_APP_HOME_LOCATION.split(',')
      lon = arr[0] && !isNaN(Number(arr[0]))? Number(arr[0]) : lon
      lat = arr[1] && !isNaN(Number(arr[1]))? Number(arr[1]): lat
      height = arr[2] && !isNaN(Number(arr[2]))? Number(arr[2]): height
    }
    this.scene.camera.setView({
      destination: new Cesium.Cartesian3.fromDegrees(lon, lat, height),
      orientation: {
        heading: 0,
        pitch: Cesium.Math.toRadians(-90.0),
        roll: 0,
      },
    });
    this._cesiumWidget._creditContainer.style.display = "none";
    // 抗锯齿
    // this.scene.fxaa = true;
    // this.scene.postProcessStages.fxaa.enabled = true;
    this.scene.skyBox.show = false;
    this.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0.5);
    this.scene.globe.depthTestAgainstTerrain = false;
    if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
      //判断是否支持图像渲染像素化处理
      this.resolutionScale = window.devicePixelRatio;
    }
    this.scene.screenSpaceCameraController.enableTilt = defaultProp.is3D; // 是否开启鼠标中键控制地图的倾斜操作
    this.scene.screenSpaceCameraController.minimumZoomDistance = Map3D.minimumZoomDistance;
    this.scene.screenSpaceCameraController.maximumZoomDistance = Map3D.maximumZoomDistance;
    // this.addMouseMiddleEvent(); // 设置鼠标中键事件
  }

  getCenter() {
    return this.scene.camera.position;
  }
  
  setCenter(center, height) {
    const heightTemp = this.scene.camera.positionCartographic.height;
    this.scene.camera.setView({
      destination: new Cesium.Cartesian3.fromDegrees(
        center ? center[0] : 103.59328136622959,
        center ? center[1] : 29.06422583115814,
        height !== null && height !== undefined ? height : heightTemp
      ),
      orientation: {
        heading: 0,
        pitch: Cesium.Math.toRadians(-90.0), //look down
        roll: 0,
      },
    });
  }

  setOperation(operation) {
    this._interactive.setInteractive(operation);
  }

  getCurrentOperation() {
    return this._interactive.current;
  }

  getCurrentOperationType() {
    return this._interactive.type;
  }

  getScale() {
    let scene = this.scene;
    let width = scene.canvas.clientWidth;
    let height = scene.canvas.clientHeight;
    let left = scene.camera.getPickRay(
      new Cesium.Cartesian2((width / 2) | 0, height - 1)
    );
    let right = scene.camera.getPickRay(
      new Cesium.Cartesian2((1 + width / 2) | 0, height - 1)
    );
    let globe = scene.globe;
    let leftPosition = globe.pick(left, scene);
    let rightPosition = globe.pick(right, scene);

    if (!leftPosition && !rightPosition) {
      return {
        distance: null,
        pixelDistance: null,
      };
    }
    let geodesic = new Cesium.EllipsoidGeodesic();
    let leftCartographic =
      globe.ellipsoid.cartesianToCartographic(leftPosition);
    let rightCartographic =
      globe.ellipsoid.cartesianToCartographic(rightPosition);

    geodesic.setEndPoints(leftCartographic, rightCartographic);
    let pixelDistance = geodesic.surfaceDistance;
    const maxBarWidth = 100;
    let distance = null;
    for (let i = distances.length - 1; i >= 0; --i) {
      if (distances[i] / pixelDistance < maxBarWidth) {
        distance = distances[i];
        return {
          distance,
          pixelDistance,
        };
      }
    }
    return {
      distance,
      pixelDistance,
    };
  }

  triggerEditModel(operation, params) {
    this._interactive.triggerEditModel(operation, params);
  }

  getCameraInfo() {
    // 获取 相机姿态信息
    const head = this.scene.camera.heading;
    const pitch = this.scene.camera.pitch;
    const roll = this.scene.camera.roll;
    const info = { head, pitch, roll };
    // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
    const position = this.scene.camera.position; //with longitude and latitude expressed in radians and height in meters.
    const cart = this.scene.camera.positionCartographic;
    // 弧度转经纬度
    const longitude = Cesium.Math.toDegrees(cart.longitude).toFixed(6);
    const latitude = Cesium.Math.toDegrees(cart.latitude).toFixed(6);
    const height = cart.height;
    return {
      lng: longitude,
      lat: latitude,
      height,
      orientation: info,
      position,
    };
  }

  loadTDMap() {
    let ano = new Cesium.UrlTemplateImageryProvider({
      url:`${tdtUrl}/DataServer?T=cia_w&x={x}&y={y}&l={z}&tk=7f3a6463a4e8b66fbfb22a3b78a9c710`,
      subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
      tilingScheme: new Cesium.WebMercatorTilingScheme(),
      maximumLevel: 17,
    });

    let layer2 = new Cesium.ImageryLayer(ano, {
      alpha: 1,
    });
    ano.name = "baseCia";
    this.imageryLayers.add(layer2);
  }

  switchLayer(type) {
    if (type == "satellite") {
      this.imageryLayers.removeAll();

      let base = this._getProvider("satellite", false);
      let layer2 = new Cesium.ImageryLayer(base, {
        alpha: 1,
      });
      this.imageryLayers.add(layer2);

      let ano = this._getProvider("satellite", true);
      let layer = new Cesium.ImageryLayer(ano, {
        alpha: 1,
      });
      this.imageryLayers.add(layer);
    } else if (type == "vector") {
      this.imageryLayers.removeAll();
      let base = this._getProvider("vector", false);
      let layer = new Cesium.ImageryLayer(base, {
        alpha: 1,
      });
      this.imageryLayers.add(layer);

      let ano = this._getProvider("vector", true);
      let layer2 = new Cesium.ImageryLayer(ano, {
        alpha: 1,
      });
      this.imageryLayers.add(layer2);
    }
  }

  _getProvider(type, islabel) {
    let source = "img_w";
    let layer = "img";
    if (type == "vector" && !islabel) {
      source = "vec_w";
      layer = "vec";
    } else if (type == "vector" && islabel) {
      source = "cva_w";
      layer = "cva";
    } else if (type == "satellite" && !islabel) {
      source = "img_w";
      layer = "img";
    } else if (type == "satellite" && islabel) {
      source = "cia_w";
      layer = "cia";
    }

    let ano = new Cesium.UrlTemplateImageryProvider({
      url:
        "https://t{s}.tianditu.gov.cn/DataServer?T=" +
        source +
        "&x={x}&y={y}&l={z}&tk=7f3a6463a4e8b66fbfb22a3b78a9c710",
      subdomains: ["0", "1", "2", "3", "4", "5", "6", "7"],
      tilingScheme: new Cesium.WebMercatorTilingScheme(),
      maximumLevel: 17,
    });
    return ano;
  }

  // 增加鼠标中键事件
  addMouseMiddleEvent() {
    let _this = this;
    // 监听鼠标中键按下事件
    this.screenSpaceEventHandler.setInputAction(function(event) {
      let pickRay = _this.camera.getPickRay(event.position);
      let cartesian = _this.scene.globe.pick(pickRay, _this.scene);
      if (cartesian && Cesium.defined(cartesian)) {
        // 根据当前按下的坐标点绘制一个临时maker图层
        setTimeout(() => {
          if (!_this.entities.getById('middleIcon')) {
            _this.entities.add({
              id: 'middleIcon',
              category: 'MIDDLEICON',
              position: cartesian,
              billboard: {
                image: MiddleIcon,
                width: 28,
                height: 28,
                heightReference: Cesium.HeightReference.CLAMP_TO_GROUND
              }
            });
          }
        }, 100);
      }
    }, Cesium.ScreenSpaceEventType.MIDDLE_DOWN);
    // 监听鼠标中键抬起事件
    this.screenSpaceEventHandler.setInputAction(function() {
      // 移除临时maker图层
      let entity = _this.entities.getById('middleIcon');
      if (entity) {
        _this.entities.remove(entity);
      }
    }, Cesium.ScreenSpaceEventType.MIDDLE_UP);
  }

  // 获取高程信息
  getAccurateHeight(point = [0, 0]) {
    return new Promise((resolve) => {
      // const positions = [Cesium.Cartographic.fromDegrees(point[0], point[1])];
      // const promise = Cesium.sampleTerrainMostDetailed(this.terrainProvider, positions);
      // try {
      //   Cesium.when(promise, function (data) {
      //     const terrainHeight = data[0].height;
      //     resolve(terrainHeight);
      //   });
      // } catch(error) {
      //   resolve("");
      // }

      const position = Cesium.Cartesian3.fromDegrees(point[0], point[1]);
      const cartographic = this.scene.globe.ellipsoid.cartesianToCartographic(position);
      // const position = Cesium.Cartographic.fromDegrees(point[0], point[1]);
      // const cartesian3 = Cesium.Ellipsoid.WGS84.cartographicToCartesian(position);
      // const cartographic = this.scene.globe.ellipsoid.cartesianToCartographic(cartesian3);
      const height = this.scene.globe.getHeight(cartographic);
      resolve(height);
    });
  }
}

export default Map3D;
